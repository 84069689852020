<template>
  <div>
    <b-card>
      <b-list-group>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Sipariş No
          </div>
          <div>{{ dataItem.onum }}</div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Firma Adı
          </div>
          <div>{{ dataItem.dealer_company }}</div>
          <div class="text-muted">
            {{ dataItem.member }}
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.dealer_package">
          <div class="font-weight-bold text-primary">
            Paket Adı
          </div>
          <div>{{ dataItem.dealer_package }}</div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Sipariş Durumu
          </div>
          <div>{{ dataItem.order_status }}</div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Ara Toplam
          </div>
          <div>{{ dataItem.subTotal | toCurrency }}₺</div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Vergiler Toplamı
          </div>
          <div>{{ dataItem.taxesTotal }}₺</div>
          <div class="font-small-2 text-muted">
            (KDV %{{ dataItem.tax_rate }})
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Genel Toplam
          </div>
          <div>{{ dataItem.grandTotal | toCurrency }}₺</div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Sipariş Tarihi
          </div>
          <div>{{ moment(dataItem.created).format('llll') }}</div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <b-card
      no-body
    >
      <b-card-header>
        <b-card-title>Ödeme Sistemi İşlem Sonucu</b-card-title>
      </b-card-header>
      <b-table
        :items="resultData"
        small
        responsive
        class="text-nowrap"
      />
    </b-card>
  </div>
</template>
<script>

import {
  BCard, BListGroup, BListGroupItem, BTable, BCardHeader, BCardTitle,
} from 'bootstrap-vue'

export default {
  name: 'ViewPage',
  components: {
    BCard, BListGroup, BListGroupItem, BTable, BCardHeader, BCardTitle,
  },
  computed: {
    dataItem() {
      return this.$store.getters['adminDealerOrders/dataItem']
    },
    resultData() {
      return this.$store.getters['adminDealerOrderResults/dataList']
    },
  },
  created() {
    this.getData()
    this.getResult()
  },
  methods: {
    getData() {
      this.$store.dispatch('adminDealerOrders/getDataItem', this.$route.params.id)
    },
    getResult() {
      this.$store.dispatch('adminDealerOrderResults/getDataList', {
        select: [
          'dealer_order_results.Islem_ID',
          'dealer_order_results.Sonuc',
          'dealer_order_results.Sonuc_Str',
          'dealer_order_results.GUID',
          'dealer_order_results.Islem_Tarih',
          'dealer_order_results.Dekont_ID',
          'dealer_order_results.Tahsilat_Tutari',
          'dealer_order_results.Odeme_Tutari',
          'dealer_order_results.Siparis_ID',
          'dealer_order_results.Ext_Data',
          'dealer_order_results.Banka_Sonuc_Kod',
          'dealer_order_results.PB',
          'dealer_order_results.KK_No',
          'dealer_order_results.Taksit',
          'dealer_order_results.Hash',
          'dealer_order_results.Islem_GUID',
          'dealer_order_results.SanalPOS_Islem_ID',
        ],
        where: {
          'dealer_order_results.id_dealer_orders': this.$route.params.id,
        },
      })
    },
  },
}
</script>
